<script lang="ts">
	import { Footer } from "shared";
	import { onMount } from "svelte";

	import type { LayoutData } from "./$types";

	import { dev } from "$app/environment";
	import { invalidate } from "$app/navigation";
	import { page } from "$app/stores";

	import "$lib/app.css";
	import "$lib/common.css";

	export let data: LayoutData;
	$: ({ supabase, session } = data);

	onMount(() => {
		const {
			data: { subscription },
		} = supabase.auth.onAuthStateChange((_event, updated_session) => {
			if (updated_session?.expires_at !== session?.expires_at) {
				invalidate("supabase:auth");
			}
		});

		return () => subscription.unsubscribe();
	});
</script>

<svelte:head>
	<!-- HTML Meta Tags -->
	<title>{$page.data.title ?? "For Areena ♥"}</title>
	<meta name="description" content={$page.data.description ?? "A personal minisite, by Sreetam"} />
	<meta name="image" content={$page.data.image ?? "https://thisisateapot.com/og-image.png"} />
	<meta name="theme-color" content={$page.data.theme_color ?? "#85D0CB"} />

	<!-- Opengraph Meta Tags -->
	<meta property="og:url" content={$page.data.url ?? "https://thisisateapot.com"} />
	<meta property="og:type" content="website" />
	<meta property="og:title" content={$page.data.title ?? "For Areena ♥"} />
	<meta
		property="og:description"
		content={$page.data.description ?? "A personal minisite, by Sreetam"}
	/>
	<meta
		property="og:image"
		content={$page.data.image ?? "https://thisisateapot.com/og-image.png"}
	/>

	<!-- Twitter Meta Tags -->
	<meta name="twitter:card" content="summary_large_image" />
	<meta property="twitter:domain" content="thisisateapot.com" />
	<meta property="twitter:url" content={$page.data.url ?? "https://thisisateapot.com"} />
	<meta name="twitter:title" content={$page.data.title ?? "For Areena ♥"} />
	<meta
		name="twitter:description"
		content={$page.data.description ?? "A personal minisite, by Sreetam"}
	/>
	<meta
		name="twitter:image"
		content={$page.data.image ?? "https://thisisateapot.com/og-image.png"}
	/>

	{#if !dev}
		<script
			defer
			data-domain="thisisateapot.com"
			src="/stats/js/script.js"
			data-api="/api/plsbl"
		></script>
	{/if}
</svelte:head>

<slot />

<Footer />
