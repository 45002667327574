<footer>
	<p>Sreetu ♥ Areena</p>
</footer>

<style lang="postcss">
	footer {
		position: sticky;
		top: 100vh;
		display: flex;
		justify-content: center;

		background-color: #000;
	}

	footer p {
		color: #fff;
	}
</style>
